<template>
  <div id="app">
      <div class="app-con">
        <router-view />
        <div class="home-icon">
          <div @click="refresh" class="home-img shadow-game">
            <svg t="1723514578008" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6827" width="200" height="200"><path d="M946.5 505L560.1 118.8l-25.9-25.9c-12.3-12.2-32.1-12.2-44.4 0L77.5 505c-12.3 12.3-18.9 28.6-18.8 46 0.4 35.2 29.7 63.3 64.9 63.3h42.5V940h691.8V614.3h43.4c17.1 0 33.2-6.7 45.3-18.8 12.1-12.1 18.7-28.2 18.7-45.3 0-17-6.7-33.1-18.8-45.2zM568 868H456V664h112v204z m217.9-325.7V868H632V640c0-22.1-17.9-40-40-40H432c-22.1 0-40 17.9-40 40v228H238.1V542.3h-96l370-369.7 23.1 23.1L882 542.3h-96.1z" p-id="6828" fill="#1296db"></path></svg>
          </div>
        </div>
      </div>
      <div class="foot">
        <div style="width: 100%;" class="flex space-around items-center">
          <ul class="ul-outer">
            <li><span @click="goto('/aboutUs')">About Us</span></li>
            <li><span @click="goto('/privacyStatement')">Privacy Statement</span></li>
            <li><span @click="goto('/cookieStatement')">Cookie Statement</span></li>
            <li><span @click="goto('/termsofUse')">Terms of Use</span></li>
            <li><span @click="goto('/contact')">Contact</span></li>
            <li><span @click="goto('/faq')">FAQ</span></li>
          </ul>
          <div class="foot-icon">
            <div class="instagram" style="margin-right: 14px;" @click="gotoFaceBook('https://www.instagram.com')">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                <path class="instagram-logo" d="M22.5,13.5c2.9,0,3.3,0,4.4,0.1c1.1,0,1.7,0.2,2,0.4c0.5,0.2,0.9,0.4,1.3,0.8c0.4,0.4,0.6,0.8,0.8,1.3
                                  c0.2,0.4,0.3,1,0.4,2c0.1,1.2,0.1,1.5,0.1,4.4s0,3.3-0.1,4.4c0,1.1-0.2,1.7-0.4,2c-0.2,0.5-0.4,0.9-0.8,1.3
                                  c-0.4,0.4-0.8,0.6-1.3,0.8c-0.4,0.2-1,0.3-2,0.4c-1.2,0.1-1.5,0.1-4.4,0.1s-3.3,0-4.4-0.1c-1.1,0-1.7-0.2-2-0.4
                                  c-0.5-0.2-0.9-0.4-1.3-0.8c-0.4-0.4-0.6-0.8-0.8-1.3c-0.2-0.4-0.3-1-0.4-2c-0.1-1.2-0.1-1.5-0.1-4.4s0-3.3,0.1-4.4
                                  c0-1.1,0.2-1.7,0.4-2c0.2-0.5,0.4-0.9,0.8-1.3c0.4-0.4,0.8-0.6,1.3-0.8c0.4-0.2,1-0.3,2-0.4C19.2,13.5,19.6,13.5,22.5,13.5
                                  M22.5,11.5c-3,0-3.4,0-4.5,0.1c-1.2,0.1-2,0.2-2.7,0.5c-0.7,0.3-1.3,0.7-1.9,1.3c-0.6,0.6-1,1.2-1.3,1.9c-0.3,0.7-0.5,1.5-0.5,2.7
                                  c-0.1,1.2-0.1,1.5-0.1,4.5c0,3,0,3.4,0.1,4.5c0.1,1.2,0.2,2,0.5,2.7c0.3,0.7,0.7,1.3,1.3,1.9c0.6,0.6,1.2,1,1.9,1.3
                                  c0.7,0.3,1.5,0.5,2.7,0.5c1.2,0.1,1.5,0.1,4.5,0.1s3.4,0,4.5-0.1c1.2-0.1,2-0.2,2.7-0.5c0.7-0.3,1.3-0.7,1.9-1.3
                                  c0.6-0.6,1-1.2,1.3-1.9c0.3-0.7,0.5-1.5,0.5-2.7c0.1-1.2,0.1-1.5,0.1-4.5s0-3.4-0.1-4.5c-0.1-1.2-0.2-2-0.5-2.7
                                  c-0.3-0.7-0.7-1.3-1.3-1.9c-0.6-0.6-1.2-1-1.9-1.3c-0.7-0.3-1.5-0.5-2.7-0.5C25.9,11.5,25.5,11.5,22.5,11.5L22.5,11.5z"></path>
                <path class="instagram-logo" d="M22.5,16.9c-3.1,0-5.6,2.5-5.6,5.6c0,3.1,2.5,5.6,5.6,5.6s5.6-2.5,5.6-5.6C28.1,19.4,25.6,16.9,22.5,16.9z
                                  M22.5,26.2c-2,0-3.7-1.6-3.7-3.7s1.6-3.7,3.7-3.7s3.7,1.6,3.7,3.7S24.5,26.2,22.5,26.2z"></path>
                </svg>
            </div>
            <div class="facebook" style="margin-right: 14px;" @click="gotoFaceBook('https://www.facebook.com')">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                <path class="facebook-logo" d="M22.5,11.6c-6.1,0-11,4.9-11,11c0,5.5,4,10,9.3,10.9v-7.7H18v-3.2h2.8v-2.4c0-2.8,1.6-4.3,4.2-4.3
                              c1.2,0,2.5,0.2,2.5,0.2v2.7H26c-1.4,0-1.8,0.8-1.8,1.7v2.1h3.1l-0.5,3.2h-2.6v7.7c5.3-0.8,9.3-5.4,9.3-10.9
                              C33.5,16.5,28.6,11.6,22.5,11.6z"></path>
              </svg>
            </div>
            <!-- <div style="width: 20px;"></div> -->
            <div class="tiktok" style="margin-right: 14px;" @click="gotoFaceBook('https://www.tiktok.com')">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                <path class="tiktok-logo" d="m30.6 16.7q-0.8-0.2-1.4-0.6-0.7-0.3-1.2-0.9-0.5-0.6-0.8-1.3-0.3-0.7-0.4-1.5v-0.4h-3.4v13.7c0 0.6-0.2 1.2-0.6 1.7-0.3 0.4-0.8 0.8-1.4 1-0.6 0.2-1.2 0.2-1.8 0-0.6-0.1-1.1-0.5-1.4-1-0.4-0.5-0.6-1.1-0.6-1.7 0-0.6 0.2-1.2 0.5-1.7 0.4-0.5 0.9-0.9 1.5-1.1 0.5-0.2 1.2-0.2 1.8 0v-3.5c-1.4-0.2-2.7 0.1-3.9 0.7-1.1 0.6-2.1 1.5-2.7 2.7-0.6 1.2-0.8 2.5-0.6 3.8 0.2 1.3 0.8 2.6 1.8 3.5 0.8 0.9 2 1.5 3.2 1.8 1.2 0.2 2.5 0.1 3.7-0.4 1.1-0.4 2.1-1.3 2.8-2.3 0.7-1 1.1-2.3 1.1-3.5v-7q0.5 0.4 1.1 0.6 0.6 0.3 1.2 0.5 0.6 0.2 1.2 0.3 0.7 0.1 1.3 0.1v-3.4q-0.1 0-0.3 0-0.1 0-0.2 0-0.1 0-0.3-0.1-0.1 0-0.2 0z"></path>
              </svg>
            </div>
            <!-- <div style="width: 20px;"></div> -->
            <div class="youtube" @click="gotoFaceBook('https://www.youtube.com')">
              <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 45 45" style="enable-background:new 0 0 45 45;" xml:space="preserve">
                <path class="youtube-logo" d="M33,17.2c-0.3-1-1-1.7-1.9-2c-1.7-0.5-8.6-0.5-8.6-0.5s-6.9,0-8.6,0.5c-0.9,0.3-1.7,1-1.9,2
                                c-0.5,1.7-0.5,5.3-0.5,5.3s0,3.6,0.5,5.3c0.3,1,1,1.7,1.9,2c1.7,0.5,8.6,0.5,8.6,0.5s6.9,0,8.6-0.5c0.9-0.3,1.7-1,1.9-2
                                c0.5-1.7,0.5-5.3,0.5-5.3S33.5,18.9,33,17.2z M20.2,25.8v-6.5l5.8,3.3L20.2,25.8z"></path>
                </svg>
            </div>
          </div>
          <div class="shadow-game" style="width: 140px;margin-right:100px;">
            <img style="width: 100%;height: 100%;" src="./assets/img/a.png" alt="">
          </div>
        </div>
        <div class="foot-bottom">© 2024 Ocean Gaming ZL Co.Ltd., All Rights Reserved.</div>
      </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import './style/app.css';
export default {
    name: 'app',
    data () {
        return {
          web: this.$utils.isDesktop(),
          isRefresh: true,
          dialogVisible: false,

        }
    },
    computed:{
		},
    methods: {
      ...mapMutations(['getnumGame']),
      handleOpen () {},
      handleClose () {},
      refresh () {
        this.$router.push('/')
        sessionStorage.setItem('numGame', '1')
        this.getnumGame()
      },
      goto (path) {
        this.$router.push(path)
      },
      gotoFaceBook (url) {
        window.open(url)
      },
    },
    mounted () {
    },
}
</script>
<style lang="less">
.foot {
  position: fixed;
  bottom: 0;
  height: 190px;
  color: black;
  width: 100%;
}
.ul-outer {
  width: 45%;
  font-size: 18px;
  text-decoration: underline;
  display: flex;
  flex-wrap: wrap;
  margin: 28px 0 0 100px;
  li {
    width: 33%;
    padding: 0 0 24px 0;
    span {
      cursor: pointer;
      color: black;
    }
  }
}
.foot-bottom {
  text-align: center;
  background: #29292b;
  color: #fff;
  width: 100%;
  line-height: 50px;
  position: absolute;
  bottom: 0;
}
.home-icon {
  width: 1400px;
  margin: 0 auto;
  .home-img {
    width: 50px;
    height: 50px;
    position: fixed;
    top: 20px;
    border-radius: 16px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
      padding: 10px;
      box-sizing: border-box;
    }
  }
}
.foot-icon {
  display: flex;
  // width: 120px;
  justify-content: space-evenly;
  div {
    width: 40px;
    height: 40px;
    border: 3px solid;
    border-radius: 25px;
  }
}
.instagram:hover {
  color: #ee4aaa;
  cursor: pointer;
  path {
    fill: #ee4aaa
  }
}
.facebook:hover {
  color: #1296db;
  cursor: pointer;
  path {
    fill: #1296db
  }
}
.tiktok:hover {
  cursor: pointer;
  color: rgb(9, 234, 241);
  path {
    fill: rgb(9, 234, 241);
  }
}
.youtube:hover {
  cursor: pointer;
  color: red;
  path {
    fill: red
  }
}
.app-con {
  // margin-bottom: 190px;
  height: calc(100vh - 170px);
  // overflow: scroll-y;
  overflow-x: hidden;
}
@media (min-width: 1400px) {
  .home-img {
    margin-left: -70px;
  }
}
@media (max-width: 1400px) {
  .home-img {
      margin-left: 10px;
  }
}
</style>
